import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const advertisingAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "advertisingApi",
  tagTypes: ["Advertising"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllAdvertisings: builder.query({
      query: () => ({
        url: `/advertising`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Advertising"],
    }),

    advertisProvider: builder.mutation({
      query: ({ body, id }) => ({
        url: `/advertising/advertisProvider/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
    advertisVoucher: builder.mutation({
      query: ({ body, id }) => ({
        url: `/advertising/advertisVoucher/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
    createAdvertising: builder.mutation({
      query: ({ body }) => ({
        url: `/advertising/create`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
    editAdvertising: builder.mutation({
      query: ({ body, id }) => ({
        url: `/advertising/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
    deleteAdvertising: builder.mutation({
      query: ({ id }) => ({
        url: `/advertising/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
    toggleActiveAdvertising: builder.mutation({
      query: ({ id }) => ({
        url: `/advertising/toggleActive/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Advertising"],
    }),
  }),
});

export const {
  useGetAllAdvertisingsQuery,
  useAdvertisProviderMutation,
  useAdvertisVoucherMutation,
  useEditAdvertisingMutation,
  useDeleteAdvertisingMutation,
  useCreateAdvertisingMutation,
  useToggleActiveAdvertisingMutation,
} = advertisingAPI;
export default advertisingAPI;
