import React from 'react'
import { Box, Button, MenuItem, TextField, FormControl, InputLabel, Select } from '@mui/material';
import DirectionProvider from '../../providers/DirectionProvider';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetAllProvidersQuery } from '../../data/api/provider';
import dayjs from 'dayjs';
import { useState } from 'react';
import formatDate from '../../utils/formatDate';
import { t } from 'i18next';

const ProviderFilter = ({onSubmit})=> {

    const [providerName, setProviderName] = useState('');
    const [validationError, setValidationError] = useState('');


      const { data , isLoading, isFetching, isError, error } = useGetAllProvidersQuery();

      const handleSubmit = () => {
        setValidationError('');
        const body={
          provider_id: providerName,
        }
        onSubmit(body);
      };
    
      return (
        <Box display="flex" flexDirection="column" flex="1" justifyContent="center" gap={2}>
          <Box display="flex" flexDirection="row" flex="1" justifyContent="center" gap={1}>
            <Box sx={{ width: '400px', textAlign: 'center' }}>
              <DirectionProvider>
                  <FormControl fullWidth>
                    <InputLabel>{t('provider_name')}</InputLabel>
                    <Select
                      value={providerName}
                      onChange={(e) => setProviderName(e.target.value)}
                      sx={{ textAlign: 'center' }}
                    >
                      <MenuItem value="">
                        {t('Stores')}
                      </MenuItem>
                      {data && data.data && data.data.map((provider) => (
                        <MenuItem key={provider.id} value={provider.id}>
                          {provider.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DirectionProvider>
            </Box>
          </Box>
          <Box  sx={{ display:"flex", flexDirection:"column",justifyContent:"center", width: '20%',margin:"auto", marginTop: '24px !important' }}>
            <Button variant="contained" onClick={() => handleSubmit()}>
              {t("Search")}
            </Button>
            {validationError && (
            <Box sx={{ color: 'red',margin:"auto", }}>
              {validationError}
            </Box>
          )}
          </Box>
    
        </Box>
      );
}

export default ProviderFilter


