
import {useToggleActiveProviderDepartmentMutation } from "../../data/api/providerDepartment";
import { IconButton, Switch, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { NotificationAdd } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { t } from "i18next";
import {
  setItemToNotify,
  setNotifyMode,
} from "../../data/slices/providerDepartmentSlice";
const TableHeaderCell = ({ row }) => {
  const [selectedProvider, selectedProviderResult] =
  useToggleActiveProviderDepartmentMutation();

  const handleChange = () => {
    selectedProvider({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_active}
      onChange={handleChange}
    />
  );
};

const NotifyProvider = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Tooltip arrow placement="left" title={t("Notify")}>
      <IconButton
        sx={{
          color: blue[800],
        }}
        onClick={() => {
          dispatch(setItemToNotify(row.original));
          dispatch(setNotifyMode(true));
        }}
      >
        <NotificationAdd />
      </IconButton>
    </Tooltip>
  );
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "email",
    header: "Email",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "phone",
    header: "Phone",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "address",
    header: "Address",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "company_name",
    header: "CompanyName",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "status",
    header: "IsActive",
    type: "text",
    required: false,
    size: 140,
    Cell: TableHeaderCell,
  },
  {
    accessorKey: "notify",
    header: "notify",
    type: "text",
    required: false,
    size: 140,
    Cell: NotifyProvider,
  },
  ];
  export default TableHeaders;
  