import { useTheme } from '@emotion/react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';

function AddPaymentDialog({  title, open, columns, onClose, onSubmit }) {
  const [values, setValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});



  const handleSubmit = () => {
    const errors = validateFields();
    if (Object.keys(errors).length === 0) {
      onSubmit(values);
      onClose();
    } else {
      setValidationErrors(errors);
    }
  };

  const validateFields = () => {
    const errors = {};
    columns.forEach((column) => {
      if (column.required && !values[column.accessorKey] ) {
        errors[column.accessorKey] = 'This field is required';
      }
      if (values[column.accessorKey] <= 0) {
        errors[column.accessorKey] = 'must be positive';
      }
    });
    return errors;
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: '' });
  };
 const theme = useTheme();
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
             
                <TextField
                  key={column.accessorKey}
                  dir={theme.direction}
                  label={column.header}
                  name={column.accessorKey}
                  value={values[column.accessorKey] || ''}
                  required
                  type='number'
                  autoComplete='off'
                  error={!!validationErrors[column.accessorKey]}
                  helperText={validationErrors[column.accessorKey]}
                  onChange={handleChange}
                />
              ))} 
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button color='inherit' onClick={()=>{
          setValues({});
          onClose();}}>{t("Cancel")}</Button>
        <Button color={ 'info'} onClick={()=>{
          setValues({});
          handleSubmit();}} variant="contained">
        {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPaymentDialog;
