import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";
import mapToFormData from "../../utils/mapToFormData";
import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setEditMode,
  setItemToEdit,
} from "../../data/slices/pointSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreatePointMutation,
  useGetAllPointsQuery,
  useEditPointMutation,
} from "../../data/api/point";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";

function PointPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } =
  useGetAllPointsQuery();
  const { addMode, editMode, itemToEdit } =
    useSelector((state) => state.point);

  const [createPoint, createPointResult] = useCreatePointMutation();
  const [editPoint, editPointResult] = useEditPointMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );
  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  const onSubmit = (body) => {
    body = mapToFormData(body);

    if (addMode) createPoint(body);
    if (editMode && itemToEdit) {
      editPoint({ body, id: itemToEdit.id });
    }
  };
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions={false}
          RowActions={TableActions}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      <AwesomeDialog
       data={itemToEdit}
        columns={[
          { accessorKey: "voucher_share_points", required: true, header: t("voucherSharePoints") },
        ]}
        open={addMode || editMode}
        onClose={() => {
          dispatch(setEditMode(false));
          dispatch(setAddMode(false));
          dispatch(setItemToEdit(null));
        }}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default PointPage;
