import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const clientAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "clientApi",
  tagTypes: ["Client"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: () => ({
        url: `/client`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Client"],
    }),
    getGovernments: builder.query({
      query: () => ({
        url: `/client/getGovernments`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Client"],
    }),

    deleteClient: builder.mutation({
      query: ({ id }) => ({
        url: `/client/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Client"],
    }),

    toggleActiveClient: builder.mutation({
      query: ({ id }) => ({
        url: `/client/toggleActive/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Client"],
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useGetGovernmentsQuery,
  useDeleteClientMutation,
  useToggleActiveClientMutation,
} = clientAPI;
export default clientAPI;
