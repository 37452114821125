import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./boot";
import "./setupAxiosInterceptors";
import rootReducer from "./app/data/store";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { AuthProvider } from "react-auth-kit";
import { Toaster } from "react-hot-toast";
import GoogleFontLoader from "react-google-font-loader";
import store from "./app/data/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const store = createStore(rootReducer, applyMiddleware(thunk));

root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      {/* <GoogleFontLoader fonts={[{ font: "Open Sans", weights: [400, 700] }]} /> */}
      <Toaster />

      <AuthProvider
        authType={"cookie"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>
  </I18nextProvider>
);
