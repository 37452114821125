import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const categoryAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "categoryApi",
  tagTypes: ["Category"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllCategorys: builder.query({
      query: () => ({
        url: `/category`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Category"],
    }),
    getCategorysSelectionList: builder.query({
      query: () => ({
        url: `/category/selectionList`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Category"],
    }),

    addCategory: builder.mutation({
      query: (body) => ({
        url: `/category/create`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Category"],
    }),
    editCategory: builder.mutation({
      query: ({ body, id }) => ({
        url: `/category/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Category"],
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        url: `/category/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetAllCategorysQuery,
  useGetCategorysSelectionListQuery,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoryAPI;
export default categoryAPI;
