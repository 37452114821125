import React, {  useEffect,  useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
  Button
} from '@mui/material';
import { MRT_Localization_AR } from './arabic';
import { t } from 'i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf'
import 'jspdf-autotable'



const AwesomeExportTable = ({
  columns,
  data,
  setCreateOptions,
  RowActions,
  showActions,
  exportPDF,
  showTopActions
}) => {

  const [tableData, setTableData] = useState(() => []);

  useEffect(()=>{
    if(!!data)
    setTableData(data)
    
  },[data])
  const storedLanguage = localStorage.getItem("language");

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);
  
  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };
  const handleExportPDF= () => {
    exportPDF();
    // const doc = jsPDF();
    // doc.text("test",20,10);
    // doc.autoTable({
    //   columns:columns.map((c) => c.header),
    //   body: data.map((d)=>d)
    // });
    // doc.save('table.pdf')
  };
  return (
    < >
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
       localization={storedLanguage ==="ar"?MRT_Localization_AR:{}}
        columns={columns}
        data={tableData}
        enableColumnActions = {false}
        editingMode="modal" //default
        enableColumnOrdering
        muiTableContainerProps={{
          sx: { maxHeight: '470px' },
        }}
        initialState={{ density: 'compact' }}
        // enableEditing
        muiTableBodyCellProps= {
          {align:"justify"}
        }
        renderTopToolbarCustomActions={() => {
          if(showTopActions){
            return(
              <>
              <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
              <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportPDF}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              PDF
            </Button>
              </>
            )
          }
          if(showTopActions){
            <Button
            color="primary"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
          }
          

          
        }}
      />

    </>
  );
};


AwesomeExportTable.defaultProps = {
  showActions: false,
  showTopActions: true,
  setCreateOptions: () => {},
  RowActions: () => {},
};
export default AwesomeExportTable;
