
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setEditMode, setItemToEdit, setItemToView, setViewMode } from '../../data/slices/orderSlice';
import { useDispatch, useSelector } from 'react-redux';
  import {  useGetAllOrdersQuery } from '../../data/api/order';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import flattenObject from '../../utils/flattenObject';
import OrderInvoice from './OrderInvoices';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';

function OrdersPage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllOrdersQuery();
  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit} = useSelector(state=> state.order);

  const theme = useTheme();
  
  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );




  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

 

  return (
    <>
    {data && <AwesomeTable 
    columns={columns}
    data={data.data }
    showActions ={false}
    RowActions={TableActions}
    showTopActions ={false}
    />}





    <Dialog open={viewMode} dir={theme.direction} >
      <DialogTitle textAlign="center">{}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '400px', sm: '460px', md: '500px' },
            gap: '1.5rem',
          }}
        >

        <OrderInvoice  order={itemToView}  />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button color='inherit' onClick={() => {
          dispatch(setItemToView(null))
          dispatch(setViewMode(false))
        }} >
          {t("Close")}
        </Button>

      </DialogActions>
    </Dialog>


    </>
  )
}

export default OrdersPage