import { Chip, Switch } from "@mui/material";
import { t } from "i18next";
import { useToggleActiveAdvertisingMutation } from "../../data/api/advertising";

const TableToggleCell = ({ row }) => {
  const [selectedAdvertising, selectedAdvertisingResult] =
    useToggleActiveAdvertisingMutation();

  const handleChange = () => {
    selectedAdvertising({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_active}
      onChange={handleChange}
    />
  );
};

const TableTypeCell = ({ row }) => {
  return (
    <Chip label={t(row.original.type)} color="warning" variant="outlined" />
  );
};

const TableHeaders = [
  {
    accessorKey: "lable",
    header: "Label",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "description",
    header: "Description",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "type",
    header: "Type",
    type: "text",
    required: false,
    size: 140,
    Cell: TableTypeCell,
  },

  {
    accessorKey: "status",
    header: "IsActive",
    type: "text",
    required: false,
    size: 140,
    Cell: TableToggleCell,
  },
];
export default TableHeaders;
