import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const sectionAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "sectionApi",
  tagTypes: ["Section"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllSections: builder.query({
      query: () => ({
        url: `/section`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Section"],
    }),
    getSectionsSelectionList: builder.query({
      query: () => ({
        url: `/section/selectionList`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Section"],
    }),

    addSection: builder.mutation({
      query: (body) => ({
        url: `/section/create`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Section"],
    }),
    editSection: builder.mutation({
      query: ({ body, id }) => ({
        url: `/section/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Section"],
    }),
    deleteSection: builder.mutation({
      query: ({ id }) => ({
        url: `/section/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Section"],
    }),
  }),
});

export const {
  useGetAllSectionsQuery,
  useGetSectionsSelectionListQuery,
  useAddSectionMutation,
  useEditSectionMutation,
  useDeleteSectionMutation,
} = sectionAPI;
export default sectionAPI;
