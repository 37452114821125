import { CloseFullscreen, CloudDownload } from '@mui/icons-material';
import React from 'react'
import { useState } from 'react';

function ImagePreview({ data, accessorKey}) {
    const [showModal, setShowModal] = useState(false);
  
    const handleImageClick = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleDownloadImage = () => {
      const downloadLink = document.createElement('a');
      downloadLink.href = !data[accessorKey] ? '../../noimage.jpg' : `${data[accessorKey]}`;
      downloadLink.download = 'image.jpg';
      downloadLink.click();
    };
  
    return (
      <div style={{ position: 'relative' }}>
        <img
          alt="avatar"
          width={100}
          height={100}
          src={!data[accessorKey] ? '../../noimage.jpg' : `${data[accessorKey]}`}
          loading="lazy"
          style={{ borderRadius: '2%', cursor: 'pointer',backgroundColor:"#555" }}
          onClick={handleImageClick}
        />
      {showModal && (
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }}>
            <div style={{ background: '#fff', padding: '20px', borderRadius: '4px', maxWidth: '90vw', maxHeight: '90vh', position: 'relative' }}>
              <img
                alt="avatar"
                src={!data[accessorKey] ? '../../noimage.jpg' : `${data[accessorKey]}`}
                loading="lazy"
                style={{ width: '100%', height: 'auto', cursor: 'zoom-out' }}
                onClick={handleCloseModal}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    background: '#ffffff',
                    borderRadius: '50%',
                    padding: '5px',
                    width:'30px',
                    height:'30px',
                    marginRight: '10px',
                  }}
                >
                  <CloudDownload fontSize="medium" onClick={handleDownloadImage} style={{ color: '#444' }} />
                </div>
                <div
                  style={{
                    cursor: 'pointer',
                    background: '#ffffff',
                    borderRadius: '50%',
                    padding: '5px',
                    width:'30px',
                    height:'30px',
                  }}
                >
                  <CloseFullscreen fontSize="medium" onClick={handleCloseModal} style={{ color: '#444' }} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

export default ImagePreview