import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Paper,
  Badge,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import { t } from "i18next";
import { tokens } from "../../../theme";
import { useTheme } from "@emotion/react";
import DirectionProvider from "../../providers/DirectionProvider";
import Loader from "../../components/Common/Loader";

const SupportChat = () => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const messagesEndRef = useRef(null);
  const api = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const colors = tokens(theme.palette.mode);

  const getMessages = () => {
    if (selectedChannel) {
      setIsLoading(true);

      const url = `${api}/supportChat/getMessages?channel_id=${selectedChannel.id}`;

      axios.get(url).then((response) => {
        setMessages(response.data.data);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    const url = `${api}/supportChat`;
    axios.get(url).then((response) => {
      setChannels(response.data.data);
    });
  }, []);

  useEffect(() => {
    getMessages();
  }, [selectedChannel]);

  useEffect(() => {
    if (messagesEndRef.current) {
      const container = messagesEndRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [messagesEndRef.current]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent new line in the text field
      sendMessage();
    }
  };
  const sendMessage = () => {
    if (messageText.trim() === "") {
      return;
    }

    const newMessage = {
      message: messageText,
      sender_type: "admin",
    };
    const url = `${api}/supportChat/sendMessage?channel_id=${selectedChannel.id}`;

    axios
      .post(url, newMessage, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setMessageText("");
        getMessages();
      })
      .catch((error) => {
        console.error("Error sending the message:", error);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="80%"
      width="100%"
      margin="5px"
      borderRadius="32px"
      component={Paper}
    >
      <Box display="flex" flexDirection="row" height="100%">
        <Box
          width={250}
          bgcolor={colors.primary[400]}
          p={2}
          sx={{
            boxShadow: "4px 0 4px -4px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            paddingLeft: 0,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("Chats")}
          </Typography>
          <Box sx={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
            {channels?.map((channel) => (
              <Box
                key={channel.id}
                display="flex"
                alignItems="start"
                flexDirection="column"
                mb={1}
                bgcolor={
                  selectedChannel?.id === channel.id
                    ? colors.primary
                    : "transparent"
                }
                cursor="pointer"
                onClick={() => setSelectedChannel(channel)}
                p={1}
                sx={{
                  borderLeft: `4px solid ${
                    selectedChannel?.id === channel.id
                      ? "#00d975"
                      : "transparent"
                  }`,
                  "&:hover": {
                    bgcolor: "#d0d0d0",
                  },
                  borderBottom: "1px solid #e0e0e0",
                  borderRadius: 0,
                  margin: 0,
                  transition: "background-color 0.2s",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: `${
                        selectedChannel?.id === channel.id ? "#00d975" : "black"
                      }`,
                    }}
                  >
                    {channel.name}
                  </Typography>
                  {channel.admin_unreaded_messages > 0 && (
                    <Badge
                      sx={{
                        top: 13,
                        left: 10,
                      }}
                      color="secondary"
                      badgeContent={channel.admin_unreaded_messages}
                      showZero
                    />
                  )}
                </Box>
                <Typography sx={{ fontStyle: "italic", fontSize: "12px" }}>
                  {channel.phone}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          flex={1}
          p={2}
          bgcolor={colors.background}
          display="flex"
          flexDirection="column"
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box flex={1} overflow="auto" ref={messagesEndRef}>
                {messages?.map((message, index) => (
                  <Box
                    key={index}
                    bgcolor={
                      message.sender_type === "admin" ? "#f0f0f0" : "#e0e0e0"
                    }
                    borderRadius={4}
                    p={1}
                    mb={1}
                    alignSelf={
                      message.sender_type === "user" ? "flex-end" : "flex-start"
                    }
                    maxWidth="60%"
                    marginRight={message.sender_type === "admin" ? 0 : "auto"}
                    marginLeft={message.sender_type === "admin" ? "auto" : 0}
                    sx={{
                      width: "fit-content", // Set width to fit the content
                    }}
                  >
                    <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                      {message.message}
                    </Typography>
                    <Typography variant="body2" dir="ltr">
                      {message.time}
                    </Typography>
                    {message.sender_type === "admin" && (
                      <Typography variant="body2" dir="ltr">
                        {message.admin}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>

              <Box display="flex" alignItems="center" mt={2}>
                <IconButton
                  color="primary"
                  onClick={sendMessage}
                  disabled={!selectedChannel}
                >
                  <SendIcon />
                </IconButton>
                <DirectionProvider>
                  <TextField
                    label={t("TypeAMessage")}
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    autoFocus
                    disabled={!selectedChannel}
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </DirectionProvider>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SupportChat;
