import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const promotionAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "promotionApi",
  tagTypes: ["Promotion"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getPromotionsSelectionList: builder.query({
      query: () => ({
        url: `/promotion/selectionList`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Promotion"],
    }),
  }),
});

export const { useGetPromotionsSelectionListQuery } = promotionAPI;
export default promotionAPI;
