import React, {  useEffect,  useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button
} from '@mui/material';
import { MRT_Localization_AR } from './arabic';
import { t } from 'i18next';



const AwesomeTable = ({
  columns,
  data,
  setCreateOptions,
  RowActions,
  showActions,
  showTopActions
}) => {

  const [tableData, setTableData] = useState(() => []);

  useEffect(()=>{
    if(!!data)
    setTableData(data)
    
  },[data])
  const storedLanguage = localStorage.getItem("language");

  return (
    <Box>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
       localization={storedLanguage ==="ar"?MRT_Localization_AR:{}}
        columns={columns}
        data={tableData}
        enableColumnActions = {showActions}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        muiTableContainerProps={{
          sx: { maxHeight: '470px' },
        }}
        muiTableBodyCellProps= {
          {align:"justify"}
        }
        renderRowActions={({ row, table }) => RowActions(row)}
        renderTopToolbarCustomActions={() => (
          showTopActions ?
          <Button
            color="info"
            onClick={() => setCreateOptions(true)}
            variant="contained"
          >
            {t("Create")}
          </Button>:<div></div>
        )}
      />

    </Box>
  );
};


AwesomeTable.defaultProps = {
  showActions: false,
  showTopActions: true,
  setCreateOptions: () => {},
  RowActions: () => {},
};
export default AwesomeTable;
