import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ProviderSlice from "./slices/providerSlice";
import providerAPI from "./api/provider";
import providerDepartmentAPI from "./api/providerDepartment";
import ProviderDepartmentSlice from "./slices/providerDepartmentSlice";
import CategorySlice from "./slices/categorySlice";
import categoryAPI from "./api/category";
import SectionSlice from "./slices/sectionSlice";
import sectionAPI from "./api/section";
import VoucherSlice from "./slices/voucherSlice";
import voucherAPI from "./api/voucher";
import promotionAPI from "./api/promotion";
import advertisingAPI from "./api/advertising";
import AdvertisingSlice from "./slices/advertisingSlice";
import clientAPI from "./api/client";
import ClientSlice from "./slices/clientSlice";
import orderAPI from "./api/order";
import OrderSlice from "./slices/orderSlice";
import couponAPI from "./api/coupon";
import CouponSlice from "./slices/couponSlice";
import accountingAPI from "./api/accounting";
import AccountingSlice from "./slices/accountingSlice";
import notificationAPI from "./api/notification";
import NotificationSlice from "./slices/notificationSlice";
import adminAPI from "./api/admin";
import AdminSlice from "./slices/adminSlice";
import homeAPI from "./api/home";
import ConnectionSlice from "./slices/connectionSlice";
import connectionAPI from "./api/connection";
import termAPI from "./api/terms";
import TermSlice from "./slices/termSlice";
import customSlideAPI from "./api/customSlide";
import CustomSlideSlice from "./slices/customSlideSlice";
import giftAPI from "./api/gifts";
import GiftSlice from "./slices/giftSlice";
import pointAPI from "./api/point";
import PointSlice from "./slices/pointSlice";
import orderGiftAPI from "./api/orderGift";
import OrderGiftSlice from "./slices/orderGiftSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    [ProviderSlice.name]: ProviderSlice.reducer,
    [providerAPI.reducerPath]: providerAPI.reducer,
    [providerDepartmentAPI.reducerPath]: providerDepartmentAPI.reducer,
    [ProviderDepartmentSlice.name]: ProviderDepartmentSlice.reducer,
    [CategorySlice.name]: CategorySlice.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,
    [ConnectionSlice.name]: ConnectionSlice.reducer,
    [connectionAPI.reducerPath]: connectionAPI.reducer,
    [SectionSlice.name]: SectionSlice.reducer,
    [sectionAPI.reducerPath]: sectionAPI.reducer,
    [VoucherSlice.name]: VoucherSlice.reducer,
    [voucherAPI.reducerPath]: voucherAPI.reducer,
    [promotionAPI.reducerPath]: promotionAPI.reducer,
    [advertisingAPI.reducerPath]: advertisingAPI.reducer,
    [AdvertisingSlice.name]: AdvertisingSlice.reducer,
    [clientAPI.reducerPath]: clientAPI.reducer,
    [ClientSlice.name]: ClientSlice.reducer,
    [couponAPI.reducerPath]: couponAPI.reducer,
    [CouponSlice.name]: CouponSlice.reducer,
    [orderAPI.reducerPath]: orderAPI.reducer,
    [OrderSlice.name]: OrderSlice.reducer,
    [accountingAPI.reducerPath]: accountingAPI.reducer,
    [AccountingSlice.name]: AccountingSlice.reducer,
    [notificationAPI.reducerPath]: notificationAPI.reducer,
    [NotificationSlice.name]: NotificationSlice.reducer,
    [adminAPI.reducerPath]: adminAPI.reducer,
    [AdminSlice.name]: AdminSlice.reducer,
    [termAPI.reducerPath]: termAPI.reducer,
    [TermSlice.name]: TermSlice.reducer,
    [customSlideAPI.reducerPath]: customSlideAPI.reducer,
    [CustomSlideSlice.name]: CustomSlideSlice.reducer,
    [giftAPI.reducerPath]: giftAPI.reducer,
    [GiftSlice.name]: GiftSlice.reducer,
    [pointAPI.reducerPath]: pointAPI.reducer,
    [PointSlice.name]: PointSlice.reducer,
    [orderGiftAPI.reducerPath]: orderGiftAPI.reducer,
    [OrderGiftSlice.name]: OrderGiftSlice.reducer,
    [homeAPI.reducerPath]: homeAPI.reducer,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

const middlewares = [
  providerAPI.middleware,
  providerDepartmentAPI.middleware,
  categoryAPI.middleware,
  sectionAPI.middleware,
  voucherAPI.middleware,
  promotionAPI.middleware,
  advertisingAPI.middleware,
  orderAPI.middleware,
  clientAPI.middleware,
  couponAPI.middleware,
  accountingAPI.middleware,
  notificationAPI.middleware,
  adminAPI.middleware,
  homeAPI.middleware,
  connectionAPI.middleware,
  termAPI.middleware,
  customSlideAPI.middleware,
  giftAPI.middleware,
  pointAPI.middleware,
  orderGiftAPI.middleware,
];

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
});

export default store;
