import { useMemo } from 'react';
import { t } from 'i18next';
import { Box } from '@mui/material';
import { useGetProviderDepartmentByProviderMutation,  useEditProviderDepartmentMutation, useDeleteProviderDepartmentMutation } from '../../data/api/providerDepartment';
import { setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode,setItemToNotify, setNotifyMode } from '../../data/slices/providerDepartmentSlice';
import { useCreateNotificationMutation } from '../../data/api/notification';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from "../../utils/mapToFormData";
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import ProviderFilter from './ProviderFilter';
import AwesomeTable from '../../components/Awesome/AweomeTable';
import TableHeaders from './tableHeaders';
import TableActions from './tableActions'; 
import Loader from '../../components/Common/Loader';

function ProviderDepartmentPage() {

  const dispatch = useDispatch();
  const { itemToView, viewMode, deleteMode, editMode, itemToEdit, itemToDelete, itemToNotify, notifyMode } = useSelector(state => state.providerDepartment);
  const [getProviderDepartment, getProviderDepartmentResult] = useGetProviderDepartmentByProviderMutation();
  const [editProviderDepartment, editProviderDepartmentResult] =  useEditProviderDepartmentMutation();
  const [deleteProviderDepartment, deleteProviderDepartmentResult] = useDeleteProviderDepartmentMutation();
  const [notifyProviderDepartment, notifyProviderDepartmentResult] = useCreateNotificationMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );

  const onSubmit = (body) => {

    getProviderDepartment(body);
   
  };

  const oneEdite = (body) => {
    body = mapToFormData(body);
    editProviderDepartment({body, id: itemToEdit.id} );
  }


  const onNotify = (body) => {

    body.provider_department_id = itemToNotify.id;
    body.type = "other";
    body = mapToFormData(body);

    notifyProviderDepartment(body);

  };

  return (
    <>
      <Box sx={{ height: "90%", overflow: 'scroll' }}>
        <ProviderFilter onSubmit={onSubmit} />
        {getProviderDepartmentResult.isLoading ? (
          <Loader />
        ) : (
          getProviderDepartmentResult.data && (
            <Box>
              <AwesomeTable
                columns={columns}
                data={getProviderDepartmentResult.data.data}
                showActions
                RowActions={TableActions}
                showTopActions={false}
              />
            </Box>
          )
        )}
      </Box>

      <FullScreenDialog

        data={itemToView}
        columns={[
          { accessorKey: 'name', header: t('Name') },
          { accessorKey: 'email', header: t('Email') },
          { accessorKey: 'phone', header: t('Phone') },
          { accessorKey: 'address', header: t('Address') },
          { accessorKey: 'company_name', header: t('CompanyName') },
          { accessorKey: 'password', header: t('Password') },
          { accessorKey: 'government', header: t('Government') },
          { accessorKey: 'delivery_area', header: t('DeliveryArea') },
          { accessorKey: 'account_limit', header: t('AccountLimit') },
          { accessorKey: 'company_rate', header: t('CompanyRate') },
          { accessorKey: 'work_start_time', header: t('WorkStartTime') },
          { accessorKey: 'work_end_time', header: t('WorkEndTime') },
          { accessorKey: 'currency', header: t('currency') },
          { accessorKey: 'logo', type: 'image', header: t('Logo') },
          { accessorKey: 'bg_image', type: 'image', header: t('BgImage') },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null))
          dispatch(setViewMode(false))
        }}
      />

      <AwesomeDialog
        data={itemToEdit}
        columns={[
          { accessorKey: 'phone', required: true, header: t('Phone') },
          { accessorKey: 'address', required: true, header: t('Address') },
          { accessorKey: 'government', type:"Map", required: true, header: t('Government') },
          { accessorKey: 'work_start_time', required: true,  type: "time", header: t('WorkStartTime') },
          { accessorKey: 'work_end_time', required: true, type: "time", header: t('WorkEndTime') },
          { accessorKey: 'bg_image', required: !itemToView, type: 'image', header: t('BgImage') },
        ]}
        open={ editMode }
        onClose={() => {

          dispatch(setEditMode(false))
          dispatch(setItemToEdit(null))

        }}
        onSubmit={oneEdite}
      />

      <AwesomeDialog
        columns={[
          { accessorKey: 'body', required: true, header: t('Body') },
          { accessorKey: 'image', type: 'image', required: false, header: t('Image') },
        ]}
        open={notifyMode}
        onClose={() => {
          dispatch(setNotifyMode(false))
          dispatch(setItemToNotify(null))

        }}
        onSubmit={onNotify}
      />

      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null))
          dispatch(setDeleteMode(false))

        }}
        onConfirm={() => {
          deleteProviderDepartment({ id: itemToDelete.id })
        }}
        message={t("DeleteConfirmation")}
      />
    </>
  );
}
export default ProviderDepartmentPage;
