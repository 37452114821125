const TableHeaders = [
  {
    accessorKey: "label",
    header: "Label",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "value",
    header: "Value",
    type: "text",
    required: false,
    size: 140,
  },
];
export default TableHeaders;
