// Notifications.js
import React, { useState, useEffect } from "react";
import { fetchToken } from "../../../firebaseInit.js";
import { useDispatch } from "react-redux";
// import { storeFcmToken } from "../../data/home/actions/home.actions.js";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();
  const [token,setToken] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        // You can await asynchronous operations here
        await fetchToken(setTokenFound).then(data=>{
            if (data) {
                setToken(data);
              }
        });
       
      } catch (error) {
        // Handle any errors that may occur during fetching the token
        console.error("An error occurred while retrieving token. ", error);
      }
    }
  
    fetchData();
  
    if (token) {
      const tokenObj = {
        device_token: token,
      };
      // dispatch(storeFcmToken(tokenObj));
    }
  }, [setTokenFound, token]);
  
  return <></>;
};


Notifications.propTypes = {};

export default Notifications;
