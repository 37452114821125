import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";
import mapToFormData from "../../utils/mapToFormData";
import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setEditMode,
  setItemToEdit,
  setItemToView,
  setViewMode,
  setItemToDelete,
  setDeleteMode
} from "../../data/slices/giftSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateGiftMutation,
  useGetAllGiftsQuery,
  useEditGiftMutation,
  useDeleteGiftMutation
} from "../../data/api/gifts";
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function GiftsPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } =
  useGetAllGiftsQuery();
  const { itemToView, viewMode, addMode, deleteMode, editMode, itemToEdit, itemToDelete } =
    useSelector((state) => state.gift);

  const [createGift, createGiftResult] = useCreateGiftMutation();
  const [editGift, editGiftResult] = useEditGiftMutation();
  const [deleteGift, deleteGiftResult] = useDeleteGiftMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );
  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  const onSubmit = (body) => {
    body = mapToFormData(body);

    if (addMode) createGift(body);
    if (editMode && itemToEdit) {
      editGift({ body, id: itemToEdit.id });
    }
  };
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions={false}
          RowActions={TableActions}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      <FullScreenDialog

        data={itemToView}
        columns={[
          { accessorKey: 'name', header: t('Name') },
          { accessorKey: 'description', header: t('Description') },
          { accessorKey: 'count', header: t('Count') },
          { accessorKey: 'required_points', header: t('RequiredPoints') },
          { accessorKey: 'image', header: t('Image') },
          { accessorKey: 'cover_photo', header: t('CoverPhoto') },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null))
          dispatch(setViewMode(false))
        }}
      />

      <AwesomeDialog
       data={itemToEdit}
        columns={[
          { accessorKey: "name", required: true, header: t("Name") },
          { accessorKey: "description", required: false, header: t("Description") },
          { accessorKey: "count", required: true, header: t("Count") },
          { accessorKey: "required_points", required: true, header: t("RequiredPoints") },
          { accessorKey: "image", required: !itemToView, type: 'image', header: t('Image') },
          { accessorKey: 'cover_photo', required: !itemToView, type: 'image', header: t('CoverPhoto') },
        ]}
        open={addMode || editMode}
        onClose={() => {
          dispatch(setEditMode(false));
          dispatch(setAddMode(false));
          dispatch(setItemToEdit(null));
        }}
        onSubmit={onSubmit}
      />

      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null))
          dispatch(setDeleteMode(false))

        }}
        onConfirm={() => {
          deleteGift({ id: itemToDelete.id })
        }}
        message={t("DeleteConfirmation")}
      />
    </>
  );
}

export default GiftsPage;
