
import { Chip, Switch, Typography } from "@mui/material";
import { t } from "i18next";
import { Check, Close } from "@mui/icons-material";

const TableChipCell = ({ row }) => {
  let color = "info";
  switch (row.original.status) {
    case "pending": {
      color = "warning";
      break;
    }
    case "accepted": {
      color = "success";
      break;
    }
    case "canceled": {
      color = "error";
      break;
    }
    default: {
      color = "info";
    }
  }
  return <Chip label={t(row.original.status)} color={color} variant="filled" />;
};

const TableHeaders = [
  {
    accessorKey: "client.name",
    header: "Client",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "location",
    header: "Location",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "created_at",
    header: "CreatedAt",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "status",
    header: "Status",
    type: "text",
    required: false,
    size: 140,
    Cell: TableChipCell,
  },
];
export default TableHeaders;
