const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addToProvider: false,
  editMode: false,
  viewMode: false,
  deleteMode: false,
  advertiseMode: false,
  notifyMode: false,

  itemToAdd: null,
  itemToEdit: null,
  itemToView: null,
  itemToDelete: null,
  itemToAdvertise: null,
  itemToNotify: null,
};

const ProviderDepartmentSlice = createSlice({
  name: "providerDepartment",
  initialState,
  reducers: {
    setAddToProvider: (state, action) => {
      state.addToProvider = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setDeleteMode: (state, action) => {
      state.deleteMode = action.payload;
    },
    setAdvertiseMode: (state, action) => {
      state.advertiseMode = action.payload;
    },
    setNotifyMode: (state, action) => {
      state.notifyMode = action.payload;
    },
    setItemToEdit: (state, action) => {
      state.itemToEdit = action.payload;
    },
    setItemToView: (state, action) => {
      state.itemToView = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDelete = action.payload;
    },
    setItemToAdd: (state, action) => {
        state.itemToAdd = action.payload;
      },
    setItemToAdvertise: (state, action) => {
      state.itemToAdvertise = action.payload;
    },
    setItemToNotify: (state, action) => {
      state.itemToNotify = action.payload;
    },
  },
});

export const {
  setAddToProvider,
  setEditMode,
  setViewMode,
  setDeleteMode,
  setAdvertiseMode,
  setNotifyMode,
  
  setItemToAdd,
  setItemToEdit,
  setItemToView,
  setItemToDelete,
  setItemToAdvertise,
  setItemToNotify,
} = ProviderDepartmentSlice.actions;

export default ProviderDepartmentSlice;
