import VouchersPage from "../app/pages/vouchersPage";

const locale = {
  Offers: "Offers",
  Dashboard: "Dashboard",
  DriversRequests: "Drivers Requests",
  ProvidersRequests: "Providers Requests",
  ActiveDrivers: "Active Drivers",
  ActiveProviders: "Active Providers",
  Accounts: "Accounts",
  Orders: "Orders",
  TruckTypes: "Truck Types",
  SystemParameters: "System Parameters",
  SupportChats: "Support chats",
  UsersManagement: "Users Management",
  Management: "Providers Management",
  Data: "Data",
  System: "System",

  Drivers: "Drivers",
  Providers: "Providers",
  Clients: "Clients",
  Invoices: "Invoices",
  RecentOrders: "Recent Orders",

  Name: "Name",
  Email: "Email",
  Phone: "Phone",
  Address: "Address",
  IdImage: "ID Image",
  TruckImage: "Truck Image",
  HasTruck: "Has truck",
  ProfileImageUrl: "Profile Image",
  CompanyImage: "Company Image",
  TruckName: "Truck Name",
  LoadByTon: "Load by Ton",
  PlateNumber: "Plate Number",
  Symbol: "Symbol",
  Governorate: "Governorate",
  companyName: "Company Name",
  companyPhone: "Company Phone",
  Debt: "Debt",
  Payoff: "Payoff",
  Closed: "Closed",

  ConfirmationAcceptRequest: "Are you sure you want to accept this request?",
  DeactivateRequest: "Are you sure you want to deactivate this user?",
  DeleteConfirmation: "Are you sure you want to delete this?",
  Edit: "Edit",
  Delete: "Delete",
  Create: "Create",
  NoDataAvailable: "No Data Available",
  Confirmation: "Confirmation",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Close: "Close",
  Deactivate: "Deactivate",
  Transactions: "Transactions",
  AddPayoff: "AddPayoff",
  AcountLocked: "Locked status",
  RequiredAmount: "Required Amount",

  OrderNumber: "Order number",
  ProviderName: "Provider name",
  ClientName: "Client name",
  ClientPhone: "Client phone",
  TotalCost: "Total cost",
  Date: "Date",
  Description: "Description",
  Value: "Value",

  TypeAMessage: "Type a message",
  Chats: "Chats",
  ChatMessages: "Chat messages",
  RecordingInProgress: "Recording in progress",

  CreatedAt: "Created At",
  ProductsPrice: "Products Price",
  TransportCost: "Transport Cost",
  Provider: "Provider",
  NoPromotion: "No Promotion",
  Promotion: "Promotion",
  Pin: "Pin",
  PinType: "Pin Voucher In: ",
  IsPinned: "Is Pinned",
  AllVouchers : "All Vouchers",
  NewPinnedVoucher: "New Pinned VouchersPage",
  HotPinnedVoucher: "Hot Pinned Voucher",
  MostSoldPinnedVoucher: "Most Sold Pinned Voucher",
  RemovePin: "Remove Pin",
  RemovePinConfirmation: "Are you sure you want unpin this vucher??",
  ChosseVoucherToPin: "Please select the voucher you wish to pin",
  VoucherPinndedIn: "Voucher Pinnded In",
  PinVouchers: "Pin Vouchers",
  ChooseRemovePinConfirmation: "Please select the voucher you wish to Unpin",
  AddDpartment: "Add Dpartment", 
  ProviderDepartment: "Provider Department",
  ProviderEmail: "Provider Email",
  AddVocherToProviderDepartment: "Please select the branches to which you want to add the voucher.",
  AddVoucherToProviderDepartment: "AddVoucher To Branch",
  AddVoucherToSection: "Add Voucher To Section",
  Section : "Section",
  AccountType: "AccountType",
  ProviderType:"Provider Account",
  ProviderDepartmentType: "Provider Department Account ",
  NotificationDetails: "Notification Details",
  YouHaveNewMessageInChat : "You Have New Message In Chat",
  WorkStartTime:"Work Start Time",
  WorkEndTime: "Work End Time",
  Client: "Client",
  Company: "Company",
  Products: "Products",
  Price: "Price",
  Quantity: "Quantity",
  Trucks: "Trucks",
  Image: "Image",
  Cost: "Cost",
  RequestCountsByStatus: "Request Counts By Status",
  Count: "Count",
  DriverNames: "Driver Names",
  Continue: "Continue",
  Login: "Login",
  Password: "Password",
  MustBeAvalidEmail: "Must Be Avalid Email",
  EmailIsRequired: "Email Is Required",
  PasswordIsRequired: "Password Is Required",
  CompanyName: "Company Name",
  Stores: "All Stores",
  Government: "Government",
  AccountLimit: "Account Limit",
  DeliveryArea: "Delivery Area",
  IsActive: "account status",
  Logo: "Logo",
  BgImage: "Background image",
  Gifts: "Gifts",
  RequiredPoints: "Required Points",
  Points : "Points",
  voucherSharePoints: "voucher Share Points",
  out_for_delivery: " out for delivery",
  delivered : "delivered ",
  not_delivered: " not delivered ",
  OrderGift: "Order Gift"
};
export default locale;
