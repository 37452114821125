// boot.js

const DEFAULT_MODE = "light";
const DEFAULT_LANGUAGE = "ar";
const DEFAULT_DIRECTION = "rtl";

const storedMode = localStorage.getItem("mode");
const storedLanguage = localStorage.getItem("language");
const storedDirection = localStorage.getItem("direction");

if (!storedMode) {
  localStorage.setItem("mode", DEFAULT_MODE);
}

if (!storedLanguage) {
  localStorage.setItem("language", DEFAULT_LANGUAGE);
}

if (!storedDirection) {
  localStorage.setItem("direction", DEFAULT_DIRECTION);
}
