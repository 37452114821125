const mapToFormData = (body) => {
  const formData = new FormData();

  const appendToFormData = (key, value) => {
    if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (typeof value === "boolean") {
      formData.append(key, value ? 1 : 0);
    } else if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        appendToFormData(`${key}[${i}]`, value[i]);
      }
    } else if (typeof value === "object" && value !== null) {
      for (const subKey in value) {
        if (value.hasOwnProperty(subKey)) {
          appendToFormData(`${key}.${subKey}`, value[subKey]);
        }
      }
    } else {
      formData.append(key, value);
    }
  };

  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      const value = body[key];
      appendToFormData(key, value);
    }
  }

  return formData;
};

export default mapToFormData;
