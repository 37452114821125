
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import { useAddConnectionMutation, useDeleteConnectionMutation, useEditConnectionMutation, useGetAllConnectionsQuery } from '../../data/api/connection';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode } from '../../data/slices/connectionSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from '../../utils/mapToFormData';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function ConnectionsPage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllConnectionsQuery();
  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit,itemToDelete} = useSelector(state=> state.connection);
  const [createConnection, createConnectionResult] = useAddConnectionMutation();
  const [editConnection, editConnectionResult] = useEditConnectionMutation();
  const [deleteConnection, deleteConnectionResult] = useDeleteConnectionMutation();
  
  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );


  const setCreateOptions = ()=>{
    dispatch(setAddMode(true))
  }
   const onSubmit = (body) => {
    body = mapToFormData(body);
    console.log(body)
    if (addMode) createConnection(body);
    if (editMode && itemToEdit) editConnection({ body, id: itemToEdit.id });
  };


  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }


  return (
    <>
    {data && <AwesomeTable 
    columns={columns}
    data={data.data}
    showActions
    RowActions={TableActions}
    setCreateOptions={setCreateOptions}
    showTopActions 
    />}




      <AwesomeDialog
      data = {itemToEdit}
      columns={[  
        { accessorKey: 'name', header: t('Name') },
        { accessorKey: 'url', header: t('url') },
        { accessorKey: 'icon',type:'image', header: t('Icon') },
      ]}
      open={addMode || editMode}
      onClose={() => {
    
      dispatch(setEditMode(false))
      dispatch(setAddMode(false))
      dispatch(setItemToEdit(null))

      }}
      onSubmit={onSubmit}
    />
      <ConfirmationDialog
      open={deleteMode}
      onClose={()=>{
      dispatch(setItemToDelete(null))
      dispatch(setDeleteMode(false))

      }}
      onConfirm={()=>{
        deleteConnection({id:itemToDelete.id})
      }}
      message={t("DeleteConfirmation")}
      />

    </>
  )
}

export default ConnectionsPage