const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addMode: false,
  editMode: false,
  viewMode: false,
  deleteMode: false,
  pinVoucher: false,
  viewPin: false,
  removePinVoucher: false,
  itemToEdit: null,
  itemToView: null,
  itemToDelete: null,
  itemToPin: null,
  itemToPinView: null,
  itemToRemovePin:null
};

const CustomSlideSlice = createSlice({
  name: "customSlide",
  initialState,
  reducers: {
    setAddMode: (state, action) => {
      state.addMode = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setDeleteMode: (state, action) => {
      state.deleteMode = action.payload;
    },
    setItemToEdit: (state, action) => {
      state.itemToEdit = action.payload;
    },
    setItemToView: (state, action) => {
      state.itemToView = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDelete = action.payload;
    },
    setItemToPin: (state, action) => {
      state.itemToPin = action.payload;
    },
    setPinVoucher: (state, action) => {
      state.pinVoucher = action.payload;
    },
    setItemToPinView: (state, action) => {
      state.itemToPinView = action.payload;
    },
    setViewPin: (state, action) => {
      state.viewPin = action.payload;
    },
    setItemToRemovePin: (state, action) => {
      state.itemToRemovePin = action.payload;
    },
    setRemovePinVoucher: (state, action) => {
      state.removePinVoucher = action.payload;
  },
  },
});

export const {
  setAddMode,
  setEditMode,
  setViewMode,
  setDeleteMode,
  setPinVoucher,
  setItemToEdit,
  setItemToView,
  setItemToDelete,
  setItemToPin,
  setItemToPinView,
  setViewPin,
  setItemToRemovePin,
  setRemovePinVoucher
} = CustomSlideSlice.actions;

export default CustomSlideSlice;
