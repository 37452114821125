import { Chip, Switch } from "@mui/material";
import { t } from "i18next";
import { Check, Close, Lock, LockOpen } from "@mui/icons-material";
import { useToggleActiveCouponMutation } from "../../data/api/coupon";

const TableToggleCell = ({ row }) => {
  const [selectedCoupon, selectedCouponResult] =
    useToggleActiveCouponMutation();

  const handleChange = () => {
    selectedCoupon({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_active}
      onChange={handleChange}
    />
  );
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "phone",
    header: "Phone",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "company_name",
    header: "CompanyName",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "type",
    header: "AccountType",
    type: "text",
    required: false,
    size: 140,
    Cell: ({ row }) => (
      <span>
        {row.original.type === "provider"
          ? t("ProviderType")
          : t("ProviderDepartmentType")}
      </span>
    ),
  },

  {
    accessorKey: "account_limit",
    header: "AccountLimit",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "amount",
    header: "RequiredAmount",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "is_locked",
    header: "IsActive",
    type: "text",
    required: false,
    size: 140,
    Cell: ({ row }) =>
      row.original.is_locked ? (
        <Lock color="error" />
      ) : (
        <LockOpen color="success" />
      ),
  },
];
export default TableHeaders;
