import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const adminAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "adminApi",
  tagTypes: ["Admin"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllAdmins: builder.query({
      query: () => ({
        url: `/admin`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Admin"],
    }),
    getProfileInfo: builder.query({
      query: () => ({
        url: `/profile/loggedAdmin`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Admin"],
    }),
    createAdmin: builder.mutation({
      query: (body) => ({
        url: `/admin`,
        method: "POST",
        body,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Admin"],
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `/admin/${id}`,
        method: "DELETE",
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Admin"],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: `/profile/updateProfile`,
        method: "PUT",
        body,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Admin"],
    }),
  }),
});

export const {
  useGetProfileInfoQuery,
  useGetAllAdminsQuery,
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useUpdateProfileMutation,
} = adminAPI;
export default adminAPI;
