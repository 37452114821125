import { Box } from '@mui/material'
import React from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { useState } from 'react';
import { useRef } from 'react';
import { useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import getAreaName from './getAreaName';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
function AwesomMap({callback}) {
    const center = {
        lat: 33.063924198120645,
        lng: 44.03320312500001,
      }
    const [draggable, setDraggable] = useState(true)
    const [position, setPosition] = useState(center)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () =>  ({
          dragend() {
          const marker = markerRef.current
          if (marker != null) {
            const latLng = marker.getLatLng();
            setPosition(latLng)
            
            getAreaName(latLng.lat,latLng.lng).then((result) => {
                callback({name:result.state??"",latLng:latLng})
              })
          }
        },
      }),
      [],
    )
 
  return (
    <Box > 
   <MapContainer
          center={[33.063924198120645, 44.03320312500001]}
          zoom={6}
          scrollWheelZoom={true}
          style={{ width: "100%", height: "calc(100vh - 4rem)" }}
          lang="ar"
        >
     <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
       <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      icon={new L.Icon.Default()}
      ref={markerRef}>
 
    </Marker>
</MapContainer>
  </Box>
  )
}

export default AwesomMap